import React, { useState } from 'react';
import Logo from '../../assets/img/logo_luisa.png';
import { Link, useLocation } from 'react-router-dom';
import handleLogout from '../../functions/functions';
import Dropdown from 'react-bootstrap/Dropdown';

const TopbarCoparmex = () => {
    const location = useLocation();
    const [activeButton, setActiveButton] = useState<string>(location.pathname);

    const luisa_username = localStorage.getItem('luisa_username');

    const handleSection = (section: string) => {
        setActiveButton(section);
    };

    const sections = [
        { name: 'Luisa', route: '/home' },
        { name: 'Vinculación', route: '/linkup' },
        { name: 'Publicidad', route: '/advertising' },
        { name: 'Eventos', route: '/events' },
        { name: 'Configuración', route: '/config' },
    ];

    return (
        <>
          {/* First section with position-relative */}
          <div className='w-100 p-4 bg-coparmex-blue topbar-coparmex position-relative' style={{ zIndex: 10 }}>
            <div className="row align-items-center">
              {/* Logo */}
              <div className="col-lg-3 col-md-5 col-sm-5">
                <img className="coparmex-logo" src={Logo} alt="COPARMEX Logo" />
              </div>
      
              {/* Placeholder for middle section */}
              <div className="col-lg-6 col-md-2 col-sm-2">
                {/* Puedes agregar contenido aquí si es necesario */}
              </div>
      
              {/* Dropdown */}
              <div className="col-lg-3 col-md-5 col-sm-5 text-end">
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary btn-lg text-white border border-0 dropToggle" id="dropdown-basic">
                    {luisa_username} <i className="bi bi-person-circle"></i>
                  </Dropdown.Toggle>
      
                  <Dropdown.Menu className='dropMenu'>
                    <Dropdown.Item className='btn-primary btnPerfil dropItem' href="#"><i className="bi bi-person-circle me-2"></i>Mi perfil</Dropdown.Item>
                    <Dropdown.Item className='btnCerrarSesion dropItem' href="#" onClick={handleLogout}><i className="bi bi-box-arrow-left me-2"></i>Cerrar sesión</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
      
          {/* Second Section */}
          <div className='w-100 px-4 py-2 bg-coparmex-dark' style={{ zIndex: 1 }}>
            <div className="row justify-content-center">
              {sections.map((section, index) => (
                <div
                  key={index}
                  className={`col-lg-2 col-md-2 col-sm-3 mx-1 btn ${activeButton === section.route ? 'btn-light' : 'btn-outline-light border-0'
                    }`}
                  onClick={() => handleSection(section.route)}
                >
                  <Link
                    to={section.route}
                    className="w-100 text-coparmex-primary"
                    style={{ textDecoration: 'none' }}
                  >
                    {section.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </>
      );
       
};

export default TopbarCoparmex;