import React, { useEffect, useState } from "react";
import axios from "axios";
import TopbarCoparmex from "./components/main/TopBarCoparmex";
import { LinkupProfile } from "./components/interfaces/LinkupProfile";
import { useLocation } from "react-router-dom";

const PerfilEmpresa = () => {
  const luisa_rfc = localStorage.getItem('luisa_rfc');
  const luisa_token = localStorage.getItem('luisa_token');
  const [linkupProfile, setLinkupProfile] = useState<LinkupProfile[]>([]);
  const location = useLocation();
  const user_rfc = location.state.rfc;
  //const user_id = location.state.user_id;
  useEffect(() => {

    const fetchLinkup = async () => {
      try {
        
        //const response = await axios.post(`http://localhost:3333/backend/getUserLinkupProfile`,
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getUserLinkupProfile`,
          {
            rfc: user_rfc
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${luisa_token}`,
            },
          }
        );

        if (response.status === 200) {
          const linkupInfo = response.data.business_data;
          if (linkupInfo && [linkupInfo].length > 0) {
            setLinkupProfile([linkupInfo]);
            console.log(linkupInfo);
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.log("Error response:", error.response.data.error);
          } else if (error.request) {
            console.log(
              "Request made but no response received:",
              error.request
            );
          } else {
            console.log("Error message:", error.message);
          }
        } else {
          console.error("An unknown error occurred:", error);
        }
      }
    };

    fetchLinkup();
  }, []);

  return (
    <>
      <TopbarCoparmex />
      {linkupProfile.length > 0 && (
        <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-7 col-sm-7">
              <h1>Perfil vinculación</h1>
              <h4>Razón social</h4>
              <p>{linkupProfile[0].business_name}</p>
              <h4>Actividad</h4>
              <p>{linkupProfile[0].business_activity}</p>
              <h4>Principal producto o servicio</h4>
              <p>{linkupProfile[0].business_product_service}</p>
              <h4>Descripción</h4>
              <p>-</p>
              <h4>Ubicación</h4>
              <p>{linkupProfile[0].business_location}</p>
              <hr></hr>
              <h4>Nombre</h4>
              <p>{linkupProfile[0].user_name}</p>
              <h4>Email</h4>
              <p>{linkupProfile[0].user_mail}</p>
              <h4>Teléfono</h4>
              <p>{linkupProfile[0].user_phone}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PerfilEmpresa;
