import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../../assets/css/vinculacion.css';
import "../../assets/css/tablaVinculacion.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import { LinkupInformation } from '../interfaces/LinkupResult';
import { Form, Button } from 'react-bootstrap';

const Vinculacion: React.FC = () => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const [linkupResult, setLinkupResult] = useState<LinkupInformation[]>([]);
    const navigate = useNavigate();

    const handleProfile = (id_usr: string) => {
        //console.log(id_usr);
        navigate('/result_linkup', { state: { id: 1, name: id_usr } });
    }

    const handleNewLinkup = () => {
        navigate('/profile_linkup');
    }

    const showNotification = () => {
        toast('📌 Recuerda completar tu perfil para poder aprovechar al máximo tus vinculaciones!', {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        const fetchLinkup = async () => {
            try 
            {
                // const response = await axios.post(`http://localhost:3333/backend/getLinkups`, {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getLinkups`, {
                    business_id: luisa_rfc
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });
                if (response.status === 200) {
                    const linkupData = response.data;
                    setLinkupResult([linkupData]);
                }

            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        console.log(getError);

                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            }
        };
        showNotification();
        fetchLinkup();
    }, []);

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className='flex-column align-items-center py-3 px-5 linkup-bg '>
                {/* <ToastContainer /> */}
                <div className='row justify-content-center p-linkup'>

                    <div className="col-lg-9 col-md-9 col-sm-9">

                        <div className="row">
                            <div className="col-4">
                                <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>Vinculaciones</h1>
                            </div>
                            <div className="col-4">
                                <button className='btn btn-linkup px-3' onClick={handleNewLinkup}>
                                    <i className="bi bi-box-arrow-up pe-2"></i>
                                    GENERAR NUEVA VINCULACIÓN
                                </button>
                            </div>
                            <div className="col-4">
                                <button className='btn btn-linkup'>
                                    <i className="bi bi-file-text pe-2"></i>
                                    GENERAR INFORME TRIMESTRAL
                                </button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="input-group mb-3 w-50">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input type="text" className="form-control" placeholder="Buscar vinculación" aria-label="vinculacion" aria-describedby="basic-addon1"/>
                                <button className="btn btn-outline-secondary" type="button">Buscar</button>
                            </div>
                            <Dropdown className='col-4'>
                                <Dropdown.Toggle><i className="bi bi-sliders"></i></Dropdown.Toggle>
                                <Dropdown.Menu className='p-2'>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="dropdownSelect1">
                                            <Form.Label>Tipo de vinculación</Form.Label>
                                            <Form.Select>
                                                <option value="0" selected>Selecione una opción</option>
                                                <option value="1">Primera opción</option>
                                                <option value="2">Segunda opción</option>
                                                <option value="3">Tercera opción</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="dropdownSelect2">
                                            <Form.Label>Categoria de vinculación</Form.Label>
                                            <Form.Select>
                                                <option value="0" selected>Sellecione una opción</option>
                                                <option value="A">Opción A</option>
                                                <option value="B">Opción B</option>
                                                <option value="C">Opción C</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Button variant="primary" className="w-100 mt-2">
                                            Aplicar
                                        </Button>
                                    </Form>
                                </Dropdown.Menu>                            
                            </Dropdown>
                        </div>
                    </div>
                    <div className='col-lg-10 col-md-10 col-sm-10'>
                        <section className="wrapper">
                            <main className="row title">
                                <ul className="d-flex justify-content-center align-items-baseline">
                                    <li className="widhtFirstli">Nombre de la vinculación</li>
                                    <li>Fecha</li>
                                    <li>Tipo</li>
                                    <li>Categorías</li>
                                </ul>
                            </main>
                        </section>
                        {linkupResult.map((linkupInfo, index) => (
                            <div key={index}>
                                <section key={index} className="row-fadeOut-wrapper">
                                    <article className="row transitionLink">
                                        <ul className="d-flex justify-content-center align-items-baseline" onClick={() => handleProfile(linkupInfo.id)} >
                                            <li className="widhtFirstli">
                                                <button className='btn btn-link text-decoration-none'>Vinculación 1</button>
                                            </li>
                                            <li>{new Date(linkupInfo.linkup_date).toLocaleDateString()}</li>
                                            <li>{linkupInfo.linkup_type}</li>
                                            <li>{linkupInfo.linkup_tags.join(", ")}</li>
                                        </ul>
                                        <ul className="more-content">
                                            <li>
                                                <div key={index}>
                                                    {linkupInfo.linkup_information.map((business, index) => (
                                                        <li
                                                            key={index}
                                                            className='li-w d-flex align-items-center'
                                                        >
                                                            <h5 className='fw-bold mb-0'>
                                                                <div className="circle-blue"></div> {business.business_name}
                                                            </h5>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-small'> {business.linkup_type}</span>
                                                        </li>
                                                    ))}
                                                </div>
                                            </li>
                                        </ul>
                                    </article>
                                </section>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </>
    );
};


export default Vinculacion;
