// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Poppins-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Poppins-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Poppins-Black;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: Poppins-Bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: Poppins-Italic;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: Poppins-Medium;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: Poppins-Regular;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

body {
  margin: 0;
  font-family: Poppins-Regular;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,4CAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,4CAAqC;AACvC;;AAEA;EACE,2BAA2B;EAC3B,4CAAuC;AACzC;;AAEA;EACE,2BAA2B;EAC3B,4CAAuC;AACzC;;AAEA;EACE,4BAA4B;EAC5B,4CAAwC;AAC1C;;AAEA;EACE,SAAS;EACT,4BAA4B;AAC9B","sourcesContent":["@font-face {\n  font-family: Poppins-Black;\n  src: url('../fonts/Poppins-Black.ttf');\n}\n\n@font-face {\n  font-family: Poppins-Bold;\n  src: url('../fonts/Poppins-Bold.ttf');\n}\n\n@font-face {\n  font-family: Poppins-Italic;\n  src: url('../fonts/Poppins-Italic.ttf');\n}\n\n@font-face {\n  font-family: Poppins-Medium;\n  src: url('../fonts/Poppins-Medium.ttf');\n}\n\n@font-face {\n  font-family: Poppins-Regular;\n  src: url('../fonts/Poppins-Regular.ttf');\n}\n\nbody {\n  margin: 0;\n  font-family: Poppins-Regular;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
