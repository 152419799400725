import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Login from '../auth/Login';
import Main from '../main/Main';
import Register from '../auth/Register';
import Forgot_password from '../auth/Forgot_password';
import Change_password from '../auth/Change_password';
import Verify_email from '../auth/Verify_email';
import Settings from '../../Settings';
import PerfilVinculacion from '../linkups/PerfilVinculacion';
import PerfilEmpresa from '../../PerfilEmpresa';
import PerfilUsuario from '../settings/Perfil_Usuario';
import Home from '../../Home';
import Vinculacion from '../linkups/Vinculacion';
import ResultadoVinculacion from '../linkups/ResultadoVinculacion';

function App() {

  const token = () => {
    return localStorage.getItem('luisa_token'); // Get the token from local storage
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <Routes>
            <Route path='/' element={token() ? <Vinculacion /> : <Navigate to="/login" />} />
            {/*<Route path='/register' element={<Register />} />*/}
            <Route path='/forgot_password' element={<Forgot_password />} />
            <Route path='/change_password' element={<Change_password />} />
            <Route path='/verify_email' element={<Verify_email />} />
            <Route path='/profile_linkup' element={token() ? <PerfilVinculacion /> : <Navigate to="/login" />} />
            <Route path='/linkup' element={token() ? <Vinculacion /> : <Navigate to="/login" />} />
            <Route path='/result_linkup' element={token() ? <ResultadoVinculacion /> : <Navigate to="/login" />} />
            <Route path='/profile_business' element={token() ? <PerfilEmpresa /> : <Navigate to="/login" />} />
            <Route path='/profile_user' element={token() ? <PerfilUsuario /> : <Navigate to="/login" />} />
            { 

              <Route
                path="/login"
                element={token() ? <Navigate to="/" /> : <Login />}
              />}

            <Route
              path="/home"
              element={token() ? <Vinculacion /> : <Navigate to="/login" />}
            />
          </Routes>
        </Router>
      </LocalizationProvider>
    </div>
  );
}

export default App;