import axios from 'axios';
import React, { useState, ChangeEvent, useEffect } from 'react';
import pdf from '../../assets/files/terminos_condiciones.pdf';
import loadingGif from '../../assets/img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/main.css';
import '../../assets/css/vinculacion.css'
import '../../assets/css/perfilVinculacion.css'
import ConfirmationModal from '../modals/ConfirmationModal';
import { Button, Form, Modal } from 'react-bootstrap';
import TextAreaModal from '../modals/TextAreaModal';
import GeneratedLinkupModal from '../modals/GeneratedLinkupModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Files } from '../interfaces/KnowledgeInterface';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js`;

function PerfilVinculacion() {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const navigate = useNavigate();

    const [webPage, setWebPage] = useState('');
    const [webPageError, setWebPageError] = useState(false);
    const [webPageInvalid, setWebPageInvalidError] = useState(false);
    const [misionError, setMisionError] = useState(false);
    const [visionError, setVisionError] = useState(false);
    const [descripcionError, setDescripcionError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);
    const [termsConditionsError, setTermsConditionsError] = useState(false);
    const [categoryError, setCategoryError] = useState(false);

    const [selectedLinkup, setSelectedLinkup] = useState('');

    const [tags, setTags] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    /* Modales */
    const [confirmModalType, setConfirmModalType] = useState('✅')
    const [confirmModalText, setConfirmModalText] = useState('Resultado de la Operación');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const [modalProfilesLink, setModalProfilesLink] = useState<any[]>([]);  // Perfiles para el carrusel
    const [confirmModalTextLink, setConfirmModalTextLink] = useState<string>('');
    const [isModalOpenLink, setIsModalOpenLink] = useState<boolean>(false);
    const [userId, setUserId] = useState('');

    const [isFilesModal, setFilesModal] = useState(false);

    //Files
    const [files, setFiles] = useState<File[]>([]);
    const maxFileSize = 5 * 1024 * 1024; //5MB

    /* TextArea */
    const [mision, setMision] = useState<string>('');
    const [vision, setVision] = useState<string>('');
    const [descripcion, setDescripcion] = useState<string>('');

    const [showMisionModal, setMisionModal] = useState<boolean>(false);
    const [showVisionModal, setVisionModal] = useState<boolean>(false);
    const [showDescripcionModal, setDescripcionModal] = useState<boolean>(false);

    const handleTextArea1 = (newText: string) => { setMision(newText); handleMisionChange(newText) };
    const handleTextArea2 = (newText: string) => { setVision(newText); handleVisionChange(newText) };
    const handleTextArea3 = (newText: string) => { setDescripcion(newText); handleDescripcionChange(newText) }

    const textAreaMision = "Mision";
    const textAreaVision = "Visión";
    const textAreaDescripcion = "Descripción";

    //PDF
    const [showPdfModal, setShowPdfModal] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    //multi-step form
    const [currentStep, setCurrentStep] = useState(1);

    const displayStep = (stepNumber: number) => {
        if (stepNumber >= 1 && stepNumber <= 6) {
            setCurrentStep(stepNumber);
        }
    };

    const nextStep = () => {
        if (currentStep < 6) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const updateProgressBar = () => {
        return ((currentStep - 1) / 5) * 100;
    };

    const handleFileChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
        let valid = true;

        const file = event.target.files?.[0];

        if (file) {
            if (file.type !== 'application/pdf') {
                valid = false;
                setConfirmModalType('⚠️');
                setModalMessage('Solo se aceptan archivos PDF');
                setIsModalOpen(true);
                event.target.value = "";
            }

            if (file.size > maxFileSize) {
                valid = false;
                //setFilesModal(false);
                setConfirmModalType('⚠️');
                setModalMessage('Solo se aceptan archivos de máximo 5MB');
                setIsModalOpen(true);
                event.target.value = "";
            }

            if (valid) {
                setFiles([file]);
            }
        }
    };

    const handleWebPageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setWebPage(event.target.value);
        setWebPageError(false);
    };

    const handleInvalidWebPageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setWebPage(event.target.value);
        setWebPageInvalidError(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ((e.key === 'Enter' || e.key === ' ') && inputValue.trim()) {
            e.preventDefault();
            if (!tags.includes(inputValue.trim())) {
                setTags([...tags, inputValue.trim()]);
            }
            setInputValue('');
        }

        if (e.key === 'Enter' && inputValue === '') {
            e.preventDefault();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryError(false);
        const value = e.target.value;

        if (value === ' ' || (value.endsWith(' ') && inputValue.endsWith(' '))) {
            return;
        }

        setInputValue(value);
    };

    const handleRemoveTag = (index: number, e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setTags(tags.filter((_, i) => i !== index));
    };

    const handleMisionChange = (text: any) => {
        setMision(text);
        setMisionError(false);
    };

    const handleVisionChange = (text: any) => {
        setVision(text);
        setVisionError(false);
    };

    const handleDescripcionChange = (text: any) => {
        setDescripcion(text);
        setDescripcionError(false);
    };

    const handleTermsConditionsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTermsConditions(!termsConditions);
        setTermsConditionsError(false);
    };

    const handleSelectLinkup = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLinkup(event.target.value);
    };

    const handleSkipForm = () => {
        setConfirmModalText('Aviso')
        setConfirmModalType('⚠️');
        setModalMessage('Recuerda completar tu información más adelante, esto es esencial para mejorar tu experiencia y sacar el máximo provecho de tus vinculaciones. ¡No olvides hacerlo cuando te sea posible!');
        setIsModalOpen(true);
    }

    const handleClosePdfModal = () => {
        setShowPdfModal(false);
    };

    const handleUploadFile = () => {
        setFilesModal(true);
    }

    const removeFileModal = (index: number) => {
        const fileToRemove = files[index];
        if (fileToRemove) {
            setFiles(files.filter((_, i) => i !== index));
        }
    };

    const skipForm = async () => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addLinkupProfile`, {
                rfc: luisa_rfc,
                webPage: '',
                category: '',
                mision: '',
                vision: '',
                description: '',
                linkType: ''
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            //console.log(response);

        } catch (error) {
            if (axios.isAxiosError(error)) {
                setConfirmModalText('Error')
                setConfirmModalType('❌');
                setModalMessage('Ocurrió un error al saltar el formulario, intenta de nuevo más tarde.');
                setIsModalOpen(true);
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);
                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    }

    const createLinkup = async () => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addLinkupProfile`, {
                rfc: luisa_rfc,
                webPage: webPage,
                category: tags,
                mision: mision,
                vision: vision,
                description: descripcion
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
                try {
                    //const responseLinkupPrompt = await axios.post(`http://localhost:3333/backend/generateLinkup`, {
                    const responseLinkupPrompt = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generateLinkup`, {
                        rfc: luisa_rfc,
                        webPage: webPage,
                        category: tags,
                        mision: mision,
                        vision: vision,
                        description: descripcion
                    },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${luisa_token}`
                            }
                        });
                    if (responseLinkupPrompt.status === 200) {
                        setUserId(responseLinkupPrompt.data.user_id);
                        setConfirmModalText('Correcto')
                        setConfirmModalType('✅');
                        setModalMessage('¡El perfil de vinculación ha sido completado exitosamente!');
                        setIsModalOpen(true);
                    }

                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response) {
                            const getError = error.response.data.error;
                            console.log(getError);
                            setConfirmModalText('Error')
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al realizar la vinculación de tu perfil, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                        } else if (error.request) {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                            console.log('Request made but no response received:', error.request);
                        } else {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al realizar la vinculación de tu perfil, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                            console.log('Error message:', error.message);
                        }
                    } else {
                        console.error('An unknown error occurred:', error);
                    }
                }

                setWebPage('');
                setMision('');
                setVision('');
                setDescripcion('');
                setTermsConditions(false);
                setTags([]);
                setSelectedLinkup('');
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    setConfirmModalText('Error')
                    if (getError === '[Luisa-Backend] Error adding linkup profile') {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al crear tu perfil de vinculación, ponte en contacto con un administrador.');
                        setIsModalOpen(true);
                    }
                    else {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al crear tu perfil de vinculación, intenta de nuevo más tarde.');
                        setIsModalOpen(true);
                    }
                } else if (error.request) {
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
                    setIsModalOpen(true);
                    console.log('Request made but no response received:', error.request);
                } else {
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error al crear tu perfil de vinculación, intenta de nuevo más tarde.');
                    setIsModalOpen(true);
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };

    const showErrorNotification = () => {
        toast.error('Error en los datos solicitados', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let valid = true;
        setIsLoading(true);

        const isValidUrl = (string: any): boolean => {
            try {
                new URL(string);
                return true;
            } catch {
                return false;
            }
        };

        if (!webPage.trim()) {
            setWebPageError(true);
            valid = false;
        } else if (!isValidUrl(webPage)) {
            setWebPageInvalidError(true);
            valid = false;
        } else {
            setWebPageError(false);
        }

        if (tags.length === 0) {
            setCategoryError(true);
            valid = false;
        } else {
            setCategoryError(false);
        }

        if (!mision.trim()) {
            setMisionError(true);
            valid = false;
        } else {
            setMisionError(false);
        }

        if (!vision.trim()) {
            setVisionError(true);
            valid = false;
        } else {
            setVisionError(false);
        }

        if (!descripcion.trim()) {
            setDescripcionError(true);
            valid = false;
        } else {
            setDescripcionError(false);
        }

        if (!termsConditions) {
            setTermsConditionsError(true);
            valid = false;
        } else {
            setTermsConditionsError(false);
        }

        if (!valid) {
            console.log('err');
            showErrorNotification();
            setIsLoading(false);
            return;
        }

        await createLinkup();

        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className='flex-column align-items-center py-3 px-5 linkup-bg '>
                <ToastContainer />
                <div className='row justify-content-center p-login'>
                    <div className='col-sm-12 col-md-7 col-lg-7'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>Inicia con tu Vinculación</h1>

                        <div className="container mt-5">
                            <div className="progress px-1" style={{ height: '3px' }}>
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${updateProgressBar()}%` }}
                                    aria-valuenow={updateProgressBar()}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                ></div>
                            </div>

                            <div className="step-container d-flex justify-content-between">
                                <div className="step-circle" onClick={() => displayStep(1)}>1</div>
                                <div className="step-circle" onClick={() => displayStep(2)}>2</div>
                                <div className="step-circle" onClick={() => displayStep(3)}>3</div>
                                <div className="step-circle" onClick={() => displayStep(4)}>4</div>
                                <div className="step-circle" onClick={() => displayStep(5)}>5</div>
                                <div className="step-circle" onClick={() => displayStep(6)}>6</div>
                            </div>

                            <form noValidate className={`multi-step-form animate__animated animate__fadeInUp linkup-form-bg`} onSubmit={handleSubmit}>
                                {currentStep === 1 && (
                                    <div className="step step-1 animate__animated animate__fadeInRight">
                                        <div className="mb-3 w-90">
                                        <label>Portafolio de productos y servicios</label>
                                            {/* <label>Página Web</label>
                                            <input
                                                id='web'
                                                type="text"
                                                className={`form-control bg-secondary text-light centered-placeholder centered-input-container d-flex pad-web ${webPageError || webPageInvalid ? 'is-invalid' : ''}`}
                                                placeholder='Página web'
                                                onChange={(e) => {
                                                    handleWebPageChange(e);
                                                    handleInvalidWebPageChange(e);
                                                }}
                                                value={webPage}
                                                autoComplete="off"
                                            /> */}
                                            <div>
                                                <label id='input-img' className=''>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        id="rango"
                                                        accept='.pdf'
                                                        onChange={handleFileChangeModal}>
                                                    </input>
                                                    <i className="bi bi-folder-plus px-5 mx-1 border border-dark rounded-pill point"></i>
                                                </label>
                                                <label className='form-label'>Subir archivo</label>
                                                {files.length > 0 && (
                                                    <div className='row ms-1'>
                                                        {/* <Tooltip id="mod"></Tooltip> */}
                                                        {files.map((file, index) => (
                                                            <div className='border border-dark rounded-pill px-2 mt-2 mw-50 col-6 text-center' key={index}
                                                                data-tooltip-id="mod"
                                                                data-tooltip-content={file.name}
                                                                data-tooltip-place="top">

                                                                <i className="bi bi-file-earmark-text me-1"></i>
                                                                {file.name}
                                                                <span
                                                                    onClick={() => removeFileModal(index)} >
                                                                    <i className="bi bi-x ms-1"></i>
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-primary next-step" onClick={nextStep}>Siguiente</button>
                                    </div>
                                )}

                                {currentStep === 2 && (
                                    <div className="step step-2 animate__animated animate__fadeInRight">
                                        <div className="mb-3 w-90">
                                            <label>Categoriza tu empresa</label>
                                            <div className={`linkup-div bg-secondary ${categoryError ? 'invalid-cat' : ''}`}>
                                                {tags.map((tag, index) => (
                                                    <div
                                                        className='linkup-tag text-light'
                                                        key={index}
                                                    >
                                                        {tag}
                                                        <button
                                                            className='linkup-close text-light'
                                                            onClick={(e) => handleRemoveTag(index, e)}
                                                        >
                                                            x
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    className='form-control bg-secondary centered-placeholder no-focus'
                                                    placeholder='Categoriza tu empresa'
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-primary prev-step" onClick={prevStep}>Anterior</button>
                                        <button type="button" className="btn btn-primary next-step" onClick={nextStep}>Siguiente</button>
                                    </div>
                                )}

                                {currentStep === 3 && (
                                    <div className="step step-3 animate__animated animate__fadeInRight">
                                        <div className="mb-3 w-90">
                                            <label>Misión de tu empresa</label>
                                            <input
                                                className={`form-control bg-secondary centered-placeholder centered-input-container d-flex ${misionError ? 'is-invalid' : ''}`}
                                                value={mision}
                                                onClick={() => setMisionModal(true)}
                                                readOnly
                                                style={{ cursor: 'pointer' }}
                                                placeholder={textAreaMision}
                                            />
                                        </div>
                                        <button type="button" className="btn btn-primary prev-step" onClick={prevStep}>Anterior</button>
                                        <button type="button" className="btn btn-primary next-step" onClick={nextStep}>Siguiente</button>
                                    </div>
                                )}

                                {currentStep === 4 && (
                                    <div className='step step-4 animate__animated animate__fadeInRight'>
                                        <div className="mb-3 w-90">
                                            <label>Visión de tu Empresa</label>
                                            <input
                                                className={`form-control bg-secondary centered-placeholder centered-input-container d-flex ${visionError ? 'is-invalid' : ''}`}
                                                value={vision}
                                                onClick={() => setVisionModal(true)}
                                                readOnly
                                                style={{ cursor: 'pointer', marginBottom: '10px' }}
                                                placeholder={textAreaVision}
                                            />
                                        </div>
                                        <button type="button" className="btn btn-primary prev-step" onClick={prevStep}>Anterior</button>
                                        <button type="button" className="btn btn-primary next-step" onClick={nextStep}>Siguiente</button>
                                    </div>
                                )}

                                {currentStep === 5 && (
                                    <div className='step step-5 animate__animated animate__fadeInRight'>
                                        <div className="mb-3 w-90">
                                            <label>Descripción de tu empresa</label>
                                            <input
                                                className={`form-control bg-secondary centered-placeholder centered-input-container d-flex ${descripcionError ? 'is-invalid' : ''}`}
                                                value={descripcion}
                                                onClick={() => setDescripcionModal(true)}
                                                readOnly
                                                style={{ cursor: 'pointer' }}
                                                placeholder={textAreaDescripcion}
                                            />
                                        </div>
                                        <button type="button" className="btn btn-primary prev-step" onClick={prevStep}>Anterior</button>
                                        <button type="button" className="btn btn-primary next-step" onClick={nextStep}>Siguiente</button>
                                    </div>
                                )}

                                {currentStep === 6 && (
                                    <div className='step step-5 animate__animated animate__fadeInRight'>
                                        <div className="terms-position mb-4">
                                            <input
                                                id="tyc"
                                                className={`form-check-input mt-2 ${termsConditionsError ? 'is-invalid' : ''}`}
                                                type="checkbox"
                                                onChange={handleTermsConditionsChange}
                                                checked={termsConditions}
                                            />
                                            <label className="form-check-label text-dark ps-2">
                                                <span>Aceptar</span><button type='button' className='btn btn-link' onClick={() => setShowPdfModal(true)}>términos y condiciones</button>
                                            </label>
                                        </div>
                                        <button type="button" className="btn btn-primary prev-step" onClick={prevStep}>Anterior</button>
                                        <button type="submit" className="btn btn-linkup w-30 ms-1" disabled={isLoading}>
                                            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Generar vinculación'}
                                        </button>
                                    </div>
                                )}
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <ConfirmationModal show={isModalOpen}
                onHide={() => {
                    setIsModalOpen(false);
                    if (confirmModalText === 'Confirmar') {
                        navigate('/result_linkup', { state: { id: 1, name: userId } });
                    }
                }}
                text={modalMessage}
                type={confirmModalType}
                titleText={confirmModalText}
                isLoading={isLoading}
                userId={userId}
                onClick={() => {
                    if (confirmModalText === 'Aviso') skipForm();
                }}
            />

            <TextAreaModal
                show={showMisionModal}
                onHide={() => setMisionModal(false)}
                titulo={textAreaMision}
                text={mision}
                onChange={handleTextArea1}
            />

            <TextAreaModal
                show={showVisionModal}
                onHide={() => setVisionModal(false)}
                titulo={textAreaVision}
                text={vision}
                onChange={handleTextArea2}
            />

            <TextAreaModal
                show={showDescripcionModal}
                onHide={() => setDescripcionModal(false)}
                titulo={textAreaDescripcion}
                text={descripcion}
                onChange={handleTextArea3}
            />

            <GeneratedLinkupModal
                show={isModalOpenLink}
                onHide={() => setIsModalOpenLink(false)}
                profiles={modalProfilesLink}
                titleText={confirmModalTextLink}
                isLoading={isLoading}
            />

            <Modal show={showPdfModal} onHide={handleClosePdfModal} size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Términos y condiciones</Modal.Title>
                </Modal.Header>
                <Modal.Body className='m-2'>
                    <Viewer
                        fileUrl={pdf}
                        plugins={[defaultLayoutPluginInstance]}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleClosePdfModal}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PerfilVinculacion;