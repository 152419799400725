import React, { useEffect, useState, ChangeEvent } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import axios from "axios";
import config from '../../config/config';
import TopbarCoparmex from "../main/TopBarCoparmex";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LinkupProfile } from "../interfaces/LinkupProfile";

// Definir la estructura de los datos del usuario

  const fetchUserData = async () => {
    const token = localStorage.getItem('luisa_token');
    const rfc = localStorage.getItem('luisa_rfc');
  try {
    const response = await axios.post(`${config.backendURL}/getUserLinkupProfile`,{
        rfc: rfc,
      }, 
      {
      headers: {
        'Content-Type': 'application/json', 
        Authorization: `Bearer ${token}`
      },
    });
    if (response.status === 200) {
        const values = response.data.business_data;
        console.log(values);
        return response.data.business_data; // Devolver los datos recibidos
    }
  } catch (error) {
    console.error('Error al obtener los datos del usuario:', error);
    throw error; // Propagar el error para manejarlo en el componente
  }
};

  
  // Simulación de una petición al backend para actualizar los datos del usuario
  const updateUserData = async (updatedData: LinkupProfile): Promise<void> => {
    const token = localStorage.getItem('luisa_token');
    const rfc = localStorage.getItem('luisa_rfc');
    try {
      const response = await axios.patch(`${config.backendURL}/updateUserLinkupProfile${rfc}`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log('Datos guardados:', response.data);
      alert("Datos guardados correctamente");
    } catch (error) {
      console.error('Error al actualizar los datos del usuario:', error);
      alert("Error al guardar los datos");
      throw error; // Re-lanza el error para que pueda ser manejado por el llamador de la función
    }
  };

  const showNotification = () => {
    toast('📌 Recuerda completar tu perfil para poder aprovechar al máximo tus vinculaciones!', {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
  };
  //function to have an empty string if the bd does not have the info
  function setDefaultValues<LinkupProfile>(data: LinkupProfile, fields: (keyof LinkupProfile)[]): LinkupProfile {
    fields.forEach(field => {
      if (!data[field]) {
        (data[field] as any) = "";
      }
    });
    return data;
  }

function Perfil_Usuario(){
    const [userData, setUserData] = useState<LinkupProfile>({business_name:"", business_activity: "", business_product_service: "",business_description: "",business_location: "", user_name: '', user_mail: '', user_phone: "" });
    const [originalData, setOriginalData] = useState<LinkupProfile>({business_name:"", business_activity: "", business_product_service: "",business_description: "", business_location: "", user_name: '', user_mail: '', user_phone: "" });
    const [isModified, setIsModified] = useState<boolean>(false);
    const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);

      // Cargar los datos del usuario al montar el componente
    useEffect(() => {
        const getUserData = async () => {
        const data = await fetchUserData();
        setUserData(data);
        //check if there is no data in bd so that the cancel button work
        const data_original = setDefaultValues(data,["business_name", "business_activity", "business_product_service", "business_description", "business_location", 'user_phone', 'user_name', 'user_email']);
        setOriginalData(data);

        };
        getUserData();
    }, []);

      // Verificar si los datos fueron modificados
  useEffect(() => {
    const isChanged =
        userData.business_name !== originalData.business_name ||
        userData.business_activity !== originalData.business_activity ||
        userData.business_product_service !== originalData.business_product_service ||
        userData.business_description !== originalData.business_description ||
        userData.business_location !== originalData.business_location ||
        userData.user_name !== originalData.user_name ||
        userData.user_mail !== originalData.user_mail ||
        userData.user_phone !== originalData.user_phone;
    setIsModified(isChanged);
    setIsPhoneValid(userData?.user_phone?.length === 10);

  }, [userData, originalData]);  // Este useEffect se ejecuta cada vez que se modifican los inputs

  // Manejar cambios en los inputs
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    // Validar el campo de teléfono
    if (name === 'user_phone') {
      // Permitimos solo números y restringimos a 10 caracteres
      const phoneValue = value.replace(/\D/g, '');  // Elimina cualquier caracter que no sea dígito
      if (phoneValue?.length <= 10) {
        setUserData({ ...userData, user_phone: phoneValue });
        //showNotification();
      }
    } else {
      // Para otros campos, actualizamos sin validación especial
      setUserData({ ...userData, [name]: value });
    }
  };

  // Guardar los cambios
  const handleSave = async () => {
    await updateUserData(userData);
    setOriginalData(userData);  // Actualizamos los datos originales después de guardarlos
  };

  // Cancelar cambios
  const handleCancel = () => {
    console.log(originalData);
    setUserData(originalData);  // Restauramos los valores originales
  };



    return (
        <>
          <TopbarCoparmex />

    <div className="container mt-5 p-linkup">
      <Card>
        <Card.Header>
          <h2>Perfil de Usuario</h2>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="business_name">Razón social:</Form.Label>
              <Form.Control
                type="text"
                id="business_name"
                name="business_name"
                value={userData.business_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="business_activity">Actividad:</Form.Label>
              <Form.Control
                type="text"
                id="business_activity"
                name="business_activity"
                value={userData.business_activity}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label htmlFor="business_product_service">Productos y servicio:</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={2}
                    id="business_product_service"
                    name="business_product_service"
                    value={userData.business_product_service}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="business_description">Descripcion:</Form.Label>
              <Form.Control
                type="text"
                id="business_description"
                name="business_description"
                value={userData.business_description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="business_location">Ubicacion:</Form.Label>
              <Form.Control
                type="text"
                id="business_location"
                name="business_location"
                value={userData.business_location}
                onChange={handleChange}
              />
            </Form.Group>
                <hr></hr>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="user_name">Nombre:</Form.Label>
              <Form.Control
                type="text"
                id="user_name"
                name="user_name"
                value={userData.user_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="user_mail">Email:</Form.Label>
              <Form.Control
                type="user_mail"
                id="user_mail"
                name="user_mail"
                value={userData.user_mail}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="user_phone">Teléfono:</Form.Label>
              <Form.Control
                type="text"
                id="user_phone"
                name="user_phone"
                value={userData.user_phone}
                onChange={handleChange}
              />
              {!isPhoneValid && (
                <Form.Text className="text-danger">
                  El número de teléfono debe tener 10 dígitos.
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer className="text-end">
          <Button
            variant="primary"
            className="me-2"
            onClick={handleSave}
            disabled={!isModified || !isPhoneValid}
          >
            Guardar cambios
          </Button>
          <Button
            variant="secondary"
            onClick={handleCancel}
            disabled={!isModified}
          >
            Cancelar
          </Button>
        </Card.Footer>
      </Card>
    </div>
    </>
  );
  
}
export default Perfil_Usuario;