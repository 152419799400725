import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../../assets/css/vinculacion.css';
import { LinkupInformation, LinkupBusiness } from '../interfaces/LinkupResult';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate, useLocation } from 'react-router-dom';

const ResultadoVinculacion: React.FC = () => {
    const luisa_token = localStorage.getItem('luisa_token');
    const [linkupResult, setLinkupResult] = useState<LinkupInformation[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const user_id = location.state.name;

    const handleProfile = (rfc: string) => {
        //navigate('/profile_business?' + rfc);
        navigate('/profile_business', { state: { id: 1, rfc: rfc, user_id: user_id } });
    }

    const showNotification = () => {
        toast('📌 Recuerda completar tu perfil para poder aprovechar al máximo tus vinculaciones!', {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        const fetchLinkup = async () => {
            try {
                //const response = await axios.post(`http://localhost:3333/backend/getLinkupResult`, {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getLinkupResult`, {
                    user_id: user_id
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });
                if (response.status === 200) {
                    const linkupData = response.data;
                    setLinkupResult([linkupData]);
                }

            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        console.log(getError);

                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            }
        };
        //showNotification();
        fetchLinkup();
    }, []);

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className='flex-column align-items-center py-3 px-5 linkup-bg mt-5 '>
                {/* <ToastContainer /> */}
                <div className='row justify-content-center p-linkup'>

                    <div className="col-lg-7 col-md-7 col-sm-7">

                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>Resultado de la Vinculación</h1>
                        <div className="linkup-form-bg">
                            <ul className="list-group">
                                {linkupResult.map((linkupInfo, index) => (
                                    <div key={index}>
                                        {linkupInfo.linkup_information.map((business, index) => (
                                            <li key={index} className='li-w d-flex align-items-center'>
                                                <button className='btn btn-link text-decoration-none d-flex align-items-center' onClick={() => handleProfile(business.business_rfc)}>
                                                    <h5 className='fw-bold mb-0'>
                                                        <div className="circle-blue"></div> {business.business_name}
                                                    </h5>
                                                </button>
                                                <span className='mx-2'>|</span>
                                                <span className='text-small'> {business.linkup_type}</span>
                                            </li>
                                        ))}
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ResultadoVinculacion;
